<template>
  <div class="details-info">
    <div class="details-info-box">
      <div class="details-left">
        <div class="left-top">
          <div class="top-header">
            <div class="top-left">
              <div class="title">
                {{
                  this.$store.state.home[this.$route.query.type].serviceList[
                    this.$route.query.index
                  ].patentTitle
                }}
              </div>
              <p class="text1">24小时回馈专利申请</p>
              <p class="text2">专业、极速、靠谱服务品牌</p>
            </div>
            <div class="top-right">
              <div class="title">
                <div class="text1">
                  {{
                    this.$store.state.home[this.$route.query.type].serviceList[
                      this.$route.query.index
                    ].patentTitle
                  }}
                </div>
                <p class="text2">
                  保护对产品形状、图案或其结合以及色彩与形状、图案结合所做的新设计，保护期10年。
                </p>
              </div>
              <div class="price flex">
                <div class="text-right">价格：</div>
                <span class="num"
                  >￥{{
                    this.$store.state.home[this.$route.query.type].serviceList[
                      this.$route.query.index
                    ].price
                  }}</span
                >
              </div>
              <div class="project flex">
                <div class="text-right">服务项目：</div>
                <div
                  class="btn-box"
                  v-for="(item, index) in this.$store.state.home[
                    this.$route.query.type
                  ].serviceList"
                  :key="index + 'btn' + item"
                >
                  <a-button
                    :class="{ active: index == $route.query.index }"
                    @click="toPath(index)"
                    >{{ item.title }}</a-button
                  >
                </div>
              </div>
              <div class="num flex">
                <div class="text-right">数量：</div>
                <a-input-number
                  id="inputNumber"
                  v-model="value"
                  :min="1"
                  :max="10"
                />
              </div>
              <div class="submit-btn">
                <a-button type="danger" @click="showModel">立即办理</a-button>
                <a-button
                  v-if="
                    JSON.stringify(
                      $store.state.user.guwen[$route.query.type]
                    ) !== '{}'
                  "
                  type="danger"
                  @click="visible = true"
                  ghost
                  >立即咨询</a-button
                >
              </div>
            </div>
          </div>
          <div class="top-footer">
            <div>服务承诺:</div>
            <div
              class="text"
              v-for="(item, index) in textList"
              :key="index + 'text'"
            >
              <a-icon style="color: #f13a3b" type="check" />
              <span> {{ item }} </span>
            </div>
          </div>
        </div>
        <div class="left-footer">
          <!-- <div class="footer-title"> -->
          <!-- <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="服务详情" forceRender></a-tab-pane>
              <a-tab-pane key="2" tab="申请流程" forceRender></a-tab-pane>
              <a-tab-pane key="3" tab="所需材料" forceRender> </a-tab-pane>
              <a-tab-pane key="4" tab="服务优势" forceRender> </a-tab-pane>
              <a-tab-pane key="5" tab="常见问题" forceRender> </a-tab-pane>
              <a-tab-pane key="6" tab="注意事项" forceRender> </a-tab-pane>
            </a-tabs> -->
          <ul
            style="
              list-style: none;
              margin: 0px;
              padding: 0px;
              height: 48px;
              line-height: 48px;
              cursor: pointer;
              background-color: #ffffff;
              font-weight: bold;
              font-size: 16px;
              border-bottom: 2px solid #dbdbdb;
            "
          >
            <li
              style="float: left; padding: 0 30px"
              :class="[selectLi == 0 ? 'active' : '']"
            >
              <a
                href="#particulars"
                style="color: #333333; display: block; height: 48px"
                @click="firstLi(0)"
                >服务详情</a
              >
            </li>
            <li
              style="float: left; padding: 0 30px"
              :class="[selectLi == 1 ? 'active' : '']"
            >
              <a
                href="#flow"
                style="color: #333333; display: block; height: 48px"
                @click="firstLi(1)"
                >申请流程</a
              >
            </li>
            <li
              style="float: left; padding: 0 30px"
              :class="[selectLi == 2 ? 'active' : '']"
            >
              <a
                href="#materials"
                style="color: #333333; display: block; height: 48px"
                @click="firstLi(2)"
                >所需材料</a
              >
            </li>
            <li
              style="float: left; padding: 0 30px"
              :class="[selectLi == 3 ? 'active' : '']"
            >
              <a
                href="#advantage"
                style="color: #333333; display: block; height: 48px"
                @click="firstLi(3)"
                >服务优势</a
              >
            </li>
            <li
              style="float: left; padding: 0 30px"
              :class="[selectLi == 4 ? 'active' : '']"
            >
              <a
                href="#issue"
                style="color: #333333; display: block; height: 48px"
                @click="firstLi(4)"
                >常见问题</a
              >
            </li>
            <li
              style="float: left; padding: 0 30px"
              :class="[selectLi == 5 ? 'active' : '']"
            >
              <a
                href="#matter"
                style="color: #333333; display: block; height: 48px"
                @click="firstLi(5)"
                >注意事项</a
              >
            </li>
          </ul>
          <!-- </div> -->
          <div class="footer-bottom">
            <div
              v-if="$route.query.type === 'patentlist'"
              class="exterior"
              id="particulars"
            >
              <div class="title">
                什么是{{
                  this.$store.state.home[this.$route.query.type].serviceList[
                    this.$route.query.index
                  ].patentTitle
                }}？
              </div>
              <div class="item-box" v-if="$route.query.index === '0'">
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo1.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">专利局规定</div>
                    <div class="text1">
                      <!-- 外观设计专利是指对产品的整体或者局部的形状、图案或者其结合以及色彩与形状、图案的结合所作出的富有美感并适于工业应用的新设
                      计，而申请的专利权保护。 -->
                      发明，是指对产品、方法或者其改进所提出的新的技术方案。发明专利是专利法保护的专利的三种类型之一,其法律状态最为稳定且技术价值最高。发明是有价值和使用价值的成果，专利把发明的商品属性以法律形式固定下来，使之成为不得无偿占有的财产，从而保护发明者的利益。
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo2.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">行业经验</div>
                    <div class="text1">
                      <!-- 外观设计专利无需进行实质审查，形式审查合格后即会授予专利权，不通过即驳回申请。
                      办理时间：
                      外观专利的流程分为申请阶段，审查阶段，授权阶段3个阶段，如果顺利的话，整个流程下来3-6个月你就能获得证书。
                      有效期：从申请日算起，保护期限为10年。 -->

                      专利申请越早越好，作为技术方案成熟即可申请，不要求已经做出实物，切忌已公开或销售后才申请专利。
                      办理时间： 主要受官方影响，存在较大变化的可能，通常为2年。
                      有效期：从申请日起算20年，20年之后自动失效。
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-box" v-if="$route.query.index === '1'">
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo1.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">专利局规定</div>
                    <div class="text1">
                      <!-- 外观设计专利是指对产品的整体或者局部的形状、图案或者其结合以及色彩与形状、图案的结合所作出的富有美感并适于工业应用的新设
                      计，而申请的专利权保护。 -->
                      实用新型指对产品的形状、构造或者其结合所提出的适于实用新的技术方案，被又称为小发明或结构专利。形状、构造或者其结合是指产品所具有的、可以从外部观察到的确定的空间形状。技术方案可以是对产品的三维形态的空间外形所提出的技术方案。
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo2.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">行业经验</div>
                    <div class="text1">
                      <!-- 外观设计专利无需进行实质审查，形式审查合格后即会授予专利权，不通过即驳回申请。
                      办理时间：
                      外观专利的流程分为申请阶段，审查阶段，授权阶段3个阶段，如果顺利的话，整个流程下来3-6个月你就能获得证书。
                      有效期：从申请日算起，保护期限为10年。 -->

                      实用新型专利对三性的审查没有发明专利严格，形式审查合格后即会授予专利权，不通过即驳回申请。
                      办理时间： 实用新型从申请到授权的整个周期为6-9个月。
                      有效期：从申请日算起，保护期限为10年。
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-box" v-if="$route.query.index === '2'">
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo1.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">专利局规定</div>
                    <div class="text1">
                      <!-- 外观设计专利是指对产品的整体或者局部的形状、图案或者其结合以及色彩与形状、图案的结合所作出的富有美感并适于工业应用的新设
                      计，而申请的专利权保护。 -->
                      外观设计专利是指对产品的整体或者局部的形状、图案或者其结合以及色彩与形状、图案的结合所作出的富有美感并适于工业应用的新设计，而申请的专利权保护。
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo2.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">行业经验</div>
                    <div class="text1">
                      <!-- 外观设计专利无需进行实质审查，形式审查合格后即会授予专利权，不通过即驳回申请。
                      办理时间：
                      外观专利的流程分为申请阶段，审查阶段，授权阶段3个阶段，如果顺利的话，整个流程下来3-6个月你就能获得证书。
                      有效期：从申请日算起，保护期限为10年。 -->

                      外观设计专利无需进行实质审查，形式审查合格后即会授予专利权，不通过即驳回申请。
                      办理时间：
                      外观专利的流程分为申请阶段，审查阶段，授权阶段3个阶段，如果顺利的话，整个流程下来3-6个月你就能获得证书。
                      有效期：从申请日算起，保护期限为10年。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                $route.query.type === 'copyrightList' &&
                $route.query.index === '0'
              "
              class="exterior"
            >
              <div class="title">
                什么是{{
                  this.$store.state.home[this.$route.query.type].serviceList[
                    this.$route.query.index
                  ].patentTitle
                }}？
              </div>
              <div class="item-box">
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo1.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">版权局规定</div>
                    <div class="text1">
                      美术作品版权登记指的是图画、书法、设计图等等作品的版权登记服务。
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo2.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">行业经验</div>
                    <div class="text1">
                      中国公民、法人或者其他组织的作品，不论是否发表，依法享有著作权。证明版权的途径建议用户在版权局进行登记备案。美术版权登记人。可以是公司，也可以是自然人。
                      办理时间：
                      提交美术版权登记申请以后约2-3月下发版权登记证书。
                      有效期：美术版权有效期为终生有效，以及权利人死亡后50年内。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                $route.query.type === 'copyrightList' &&
                $route.query.index === '1'
              "
              class="exterior"
            >
              <div class="title">
                什么是{{
                  this.$store.state.home[this.$route.query.type].serviceList[
                    this.$route.query.index
                  ].patentTitle
                }}？
              </div>
              <div class="item-box">
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo1.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">版权局规定</div>
                    <div class="text1">
                      文字作品版权登记指的是书籍、自传、日记等等可以申请文字作品登记服务。
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo2.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">行业经验</div>
                    <div class="text1">
                      中国公民、法人或者其他组织的作品，不论是否发表，依法享有著作权。证明版权的途径建议用户在版权局进行登记备案。文字版权登记人，可以是公司，也可以是自然人。
                      办理时间：
                      提交文字作品登记申请以后约3-5个月下发版权登记证书。
                      有效期：文字作品登记有效期为终生有效，以及权利人死亡后50年内。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                $route.query.type === 'copyrightList' &&
                $route.query.index === '2'
              "
              class="exterior"
            >
              <div class="title">
                什么是{{
                  this.$store.state.home[this.$route.query.type].serviceList[
                    this.$route.query.index
                  ].patentTitle
                }}？
              </div>
              <div class="item-box">
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo1.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">版权局规定</div>
                    <div class="text1">
                      软件著作权登记指的是为了保护计算机软件著作权人的权益，软件权利人可以申请著佐权登记。
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo2.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">行业经验</div>
                    <div class="text1">
                      中国公民、法人或者其他组织对其所开发的软件，不论是否发表，依照本条例享有著作权。申请软件著作权登记是证明权利的有效手段。软件著作权登记人。可以是公司，也可以是自然人。
                      办理时间：
                      提交软件著作权登记申请以后约3-5个月下发版权登记证书。
                      有效期：软件著作权有效期为终生有效，以及权利人死亡后50年内。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$route.query.type === 'trademark'" class="process">
              <div class="title">为什么要注册商标</div>
              <div class="flow-chart-tab">
                <div
                  class="flow-chart-item"
                  v-for="(item, index) in trademarkList"
                  :key="index + 'flow'"
                >
                  <div class="logo">
                    <img :src="item.imgUrl" alt="" />
                  </div>
                  <div class="text">{{ item.text }}</div>
                  <div class="text1">{{ item.text1 }}</div>
                </div>
              </div>
            </div>
            <div class="process wrapper">
              <div id="flow" class="title">申请流程</div>
              <div class="flow-chart-tab">
                <div
                  class="flow-chart-item"
                  v-for="(item, index) in flowList"
                  :key="index + 'flow'"
                >
                  <div class="logo">
                    <img :src="item.imgUrl" alt="" />
                  </div>
                  <div class="text">{{ item.text }}</div>
                  <div class="text1">{{ item.text1 }}</div>
                </div>
              </div>
            </div>
            <div v-if="$route.query.type === 'trademark'" class="bllc">
              <div class="title">办理流程</div>
              <div class="box"></div>
            </div>
            <div v-if="$route.query.type === 'trademark'" class="zcff">
              <div class="title">商标注册服务对比</div>
              <div class="db-box">
                <div class="left">
                  <div class="left-title">{{ zzzcList.zhinengList.title }}</div>
                  <div class="left-content">
                    <div class="content-header">
                      <div
                        class="btn"
                        v-for="(item, index) in zzzcList.zhinengList.content
                          .btn"
                        :key="index + 'btn'"
                      >
                        {{ item }}
                      </div>
                    </div>
                    <div class="content-footer">
                      <div
                        class="footer-item"
                        v-for="(item, index) in zzzcList.zhinengList.content
                          .text"
                        :key="index + 'btn'"
                      >
                        <div class="footer-index">{{ index + 1 }}</div>
                        <div class="footer-item-title">{{ item.title }}</div>
                        <div class="footer-text">{{ item.text }}</div>
                        <div class="footer-text1">{{ item.text1 }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="left-footer">
                    {{ zzzcList.zhinengList.footer }}
                  </div>
                </div>
                <div class="content">VS</div>
                <div class="right">
                  <div class="right-title">顾问代理注册</div>
                  <div class="right-content">
                    <div class="content-header">
                      <div
                        class="btn"
                        v-for="(item, index) in zzzcList.guwenList.content.btn"
                        :key="index + 'btn'"
                      >
                        {{ item }}
                      </div>
                    </div>
                    <div class="content-footer">
                      <div
                        class="footer-item"
                        v-for="(item, index) in zzzcList.guwenList.content.text"
                        :key="index + 'btn'"
                      >
                        <div class="footer-index">{{ index + 1 }}</div>
                        <div class="footer-item-title">{{ item.title }}</div>
                        <div class="footer-text">{{ item.text }}</div>
                        <div class="footer-text1">{{ item.text1 }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="right-footer">
                    适用于对近似风险评估、类别选择无经验者的新用户
                  </div>
                </div>
              </div>
            </div>
            <div class="material" v-if="$route.query.type === 'patentlist'">
              <div id="materials" class="title">所需材料</div>
              <div class="material-box">
                <div class="item">
                  <div class="img qysq">
                    <div>
                      <img src="@/assets/details/qrsq-logo.png" alt="" />
                      <div>企业申请</div>
                    </div>
                  </div>
                  <div class="text">
                    <p>营业执照副本复印件加盖公章</p>
                    <p>第一发明人身份证复印件</p>
                    <p>代理委托书（我方提供，贵方盖章）</p>
                    <p>技术交底书</p>
                    <p>专利费用减缓证明函（当地知识产权局盖章）</p>
                  </div>
                </div>
                <div class="item">
                  <div class="img grsq">
                    <div>
                      <img src="@/assets/details/grsq-logo.png" alt="" />
                      <div>个人申请</div>
                    </div>
                  </div>
                  <div class="text">
                    <p>第一发明人身份证复印件</p>
                    <p>代理委托书（我方提供，贵方签字）</p>
                    <p>技术交底书</p>
                    <p>专利费用减缓请求书（申请人个人签字）</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="material" v-if="$route.query.type === 'copyrightList'">
              <div id="materials" class="title">所需材料</div>
              <div class="material-box">
                <div class="item">
                  <div class="img qysq">
                    <div>
                      <img src="@/assets/details/qrsq-logo.png" alt="" />
                      <div>企业申请</div>
                    </div>
                  </div>
                  <div class="text">
                    <p>营业执照副本复印件加盖公章</p>
                    <p>代理委托书（我方提供，贵方盖章）</p>
                    <p>作品设计说明书（我方提供，贵方盖章）</p>
                    <p>作品归属证明（我方提供，贵方盖章）</p>
                    <p>版权申请书（我方提供，贵方盖章）</p>
                  </div>
                </div>
                <div class="item">
                  <div class="img grsq">
                    <div>
                      <img src="@/assets/details/grsq-logo.png" alt="" />
                      <div>个人申请</div>
                    </div>
                  </div>
                  <div class="text">
                    <p>身份证复印件</p>
                    <p>代理委托书（我方提供，贵方签字）</p>
                    <p>作品设计说明书（我方提供，贵方签字）</p>
                    <p>作品归属证明（我方提供，贵方签字）</p>
                    <p>版权申请书（我方提供，贵方签字）</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="material" v-if="$route.query.type === 'trademark'">
              <div id="materials" class="title">所需材料</div>
              <div class="material-box">
                <div class="item">
                  <div class="img qysq">
                    <div>
                      <img src="@/assets/details/qrsq-logo.png" alt="" />
                      <div>企业申请</div>
                    </div>
                  </div>
                  <div class="text">
                    <p>营业执照副本复印件加盖公章</p>
                    <p>代理委托书（我方提供，贵方盖章）</p>
                  </div>
                </div>
                <div class="item">
                  <div class="img grsq">
                    <div>
                      <img src="@/assets/details/grsq-logo.png" alt="" />
                      <div>个人申请</div>
                    </div>
                  </div>
                  <div class="text">
                    <p>代理委托书（我方提供，贵方签字）</p>
                    <p>个体工商户营业执照副本复印件</p>
                    <p>身份证复印件</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="advantage" id="advantage">
              <div class="title">服务优势</div>
              <div class="item-box">
                <div class="item" v-if="$route.query.type !== 'copyrightList'">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo1.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">
                      {{ serviceAdvantage[$route.query.type].title }}
                    </div>
                    <div class="text1">
                      {{ serviceAdvantage[$route.query.type].text }}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo3.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">专业的顾问团队</div>
                    <div class="text1">
                      经验丰富的顾问团队，认证对待每一个案件，秉承着专业
                      的态度，严控服务质量
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo2.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">流程进度全面监控</div>
                    <div class="text1">
                      流程透明可视化、关键节点短信、微信消息提醒通知、申
                      请进度一目了然，方便查找随时掌握
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="img">
                    <img
                      src="../../assets/exterior/exterior-logo4.png"
                      alt=""
                    />
                  </div>
                  <div class="text">
                    <div class="item-title">正规平台可开发票</div>
                    <div class="text1">
                      行业口碑平台、雄厚技术及多年大数据算法经验实力支持
                      ，支持线上申请开票
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="problem" id="issue">
              <div class="title">常见问题解答</div>
              <div class="problem-box">
                <!-- v-for="(item, index) in $store.state.home.problemList[
                    $route.query.type
                  ]" -->
                <div
                  v-for="(item, index) in $store.state.home.problemList[
                    $route.query.type
                  ][$route.query.index]"
                  :key="index + 'problem'"
                  class="item"
                >
                  <div class="item-title">
                    <div class="logo">Q</div>
                    <span class="title">{{ item.title }}</span>
                  </div>
                  <div class="item-text">
                    <div class="logo">A</div>
                    <span class="text" v-html="item.text"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="precautions" id="matter">
              <div class="title">注意事项</div>
              <div class="box">
                <p>1、国家备案知识产权服务平台，担保服务质量和付款安全。</p>
                <p>
                  2、如不办理业务存在时限、请您提前告知您的专属顾问，避免案件处理逾期。
                </p>
                <p>
                  3、若因为您的过失导致时限延迟或导致发生相关费用，将由您自行承担。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="details-right">
        <div
          class="consultant"
          v-if="
            JSON.stringify($store.state.user.guwen[$route.query.type]) !== '{}'
          "
        >
          <div class="title">专业顾问帮助</div>
          <div class="box">
            <div class="nameimg">
              <img
                style="width: 100px; height: 100px"
                :src="$store.state.user.guwen[$route.query.type].avatar"
                alt=""
              />
            </div>
            <p class="name">
              {{ $store.state.user.guwen[$route.query.type].name }}
            </p>
            <p class="code">
              手机：{{ $store.state.user.guwen[$route.query.type].tel }}
            </p>
            <p class="qq">
              QQ：{{ $store.state.user.guwen[$route.query.type].qq }}
            </p>
            <div class="ewm">
              <img
                style="width: 120px; height: 120px"
                :src="$store.state.user.guwen[$route.query.type].qrcode"
                alt=""
              />
            </div>
            <div class="zx" @click="$refs.msgtype.visible = true">咨询顾问</div>
          </div>
        </div>
        <div class="title-box">
          <div class="title-item">
            <div class="title">商标注册</div>
            <div class="item-info">
              <div
                class="item"
                v-for="(item, index) in $store.state.home.trademark.serviceList"
                :key="index + 'trademark'"
                @click="toTrademark(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="title-item">
            <div class="title">专利申请</div>
            <div class="item-info">
              <div
                class="item"
                v-for="(item, index) in $store.state.home.patentlist
                  .serviceList"
                :key="index + 'patentlist'"
                @click="toPatentlist(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="title-item">
            <div class="title">版权登记</div>
            <div class="item-info">
              <div
                class="item"
                v-for="(item, index) in $store.state.home.copyrightList
                  .serviceList"
                :key="index + 'copyrightList'"
                @click="toCopyrightList(index)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="hepl">
          <div class="title">帮助中心</div>
          <div v-if="newsList.length !== 0" class="box">
            <div
              class="item"
              v-for="(item, index) in newsList"
              :key="index + 'news'"
              @click="goNews(item)"
            >
              {{ item.title }}
            </div>
          </div>
          <div
            style="text-align: center; font-size: 14px; padding: 20px"
            v-else
          >
            暂无新闻推荐！
          </div>
        </div>
      </div>
    </div>
    <a-modal
      :title="$store.state.user.guwen[$route.query.type].name"
      :visible="visible"
      @cancel="closure"
    >
      <div class="customer">
        <div class="left-head">
          <div
            style="display: flex; justify-content: space-around"
            class="content"
          >
            <div class="left">
              <img
                style="width: 100px; height: 100px"
                :src="$store.state.user.guwen[$route.query.type].avatar"
                alt=""
              />
              <div class="text">
                电话：{{ $store.state.user.guwen[$route.query.type].tel }}
              </div>
            </div>
            <div class="right">
              <div class="vx">
                <img
                  style="width: 100px; height: 100px"
                  :src="$store.state.user.guwen[$route.query.type].qrcode"
                  alt=""
                />
                <div style="text-align: center">扫码添加微信</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <span></span>
      </template>
    </a-modal>
    <Floating :consultant="$store.state.user.guwen[$route.query.type]" />
    <Model ref="model" />
    <MsgModel ref="msgtype" />
  </div>
</template>

<script>
import { news } from "../../api/index";
import Model from "../../comon/model.vue";
import Floating from "../../comon/Floating.vue";
import MsgModel from "../../comon/MsgModel.vue";
export default {
  name: "PatentPlatHomeIndex",
  components: { Model, MsgModel, Floating },
  data() {
    return {
      serviceAdvantage: {
        patentlist: {
          title: "国家专利局备案机构",
          text: "支持专利申请线上提交、线上跟踪、高效稳定对接专利局系统，快速递交至专利局",
        },
        trademark: {
          title: "国家商标局备案机构",
          text: "支持商标申请线上提交、线上跟踪、高效稳定对接商标局系统，快速递交商标局",
        },
        copyrightList: {
          title: "国家商标局备案机构",
          text: "支持商标申请线上提交、线上跟踪、高效稳定对接商标局系统，快速递交商标局",
        },
      },
      visible: false,
      newsList: [],
      selectLi: "",
      zzzcList: {
        zhinengList: {
          title: "智能自助注册",
          content: {
            btn: ["操作简单", "费用低"],
            text: [
              {
                title: "价格实惠",
                text: "自助办理",
                text1: "性价比首选",
              },
              {
                title: "智能辅助",
                text: "自动生成图样",
                text1: "提升注册速度",
              },
              {
                title: "材料预审",
                text: "审查申请材料",
                text1: "降低受理风险",
              },
            ],
          },
          footer: "适用于了解商标局审查规范，可以自主评估风险的用户",
        },
        guwenList: {
          title: "顾问代理注册",
          content: {
            btn: ["成功率高", "专业性强", "省时省力"],
            text: [
              {
                title: "专业评估",
                text: "规避近似风险",
                text1: "提高成功率",
              },
              {
                title: "类别规划",
                text: "精准规划",
                text1: "全面保护品牌",
              },
              {
                title: "省时省力",
                text: "审查申请材料",
                text1: "降低受理风险",
              },
            ],
          },
          footer: "适用于对近似风险评估、类别选择无经验者的新用户",
        },
      },
      btnList: ["发明专利", "实用新型", "外观设计"],
      value: 1,
      textList: ["全程托管", "服务贴心", "价格统一", "省心省力"],
      trademarkList: [
        {
          text: "品牌保护",
          imgUrl: require("@/assets/details/trademark-logo1.png"),
          text1: "获得品牌专用权、保护自己不被侵权与市场投入",
        },
        {
          text: "入驻电商",
          imgUrl: require("@/assets/details/trademark-logo2.png"),
          text1: "入驻天猫、京东、唯品会等电商平台的必备凭证",
        },
        {
          text: "抵押贷款",
          imgUrl: require("@/assets/details/trademark-logo3.png"),
          text1: "用于抵押、融资、贷款等",
        },
        {
          text: "闲置转让",
          imgUrl: require("@/assets/details/trademark-logo4.png"),
          text1: "闲置商标转让=无形资产向有型资产的转变",
        },
      ],
      flowList: [
        {
          text: "提交需求",
          imgUrl: require("@/assets/details/details-logo1.png"),
          text1: "确定服务，线上提交需求",
        },
        {
          text: "专属顾问服务",
          imgUrl: require("@/assets/details/details-logo2.png"),
          text1: "专业顾问评估分析制定有效申报方案",
        },
        {
          text: "申请递交",
          imgUrl: require("@/assets/details/details-logo3.png"),
          text1: "准备/完善相关材料递交至国家专利局",
        },
        {
          text: "跟进反馈",
          imgUrl: require("@/assets/details/details-logo4.png"),
          text1: "跟进受理、审查过程并反馈进度",
        },
        {
          text: "下发结果",
          imgUrl: require("@/assets/details/details-logo5.png"),
          text1: "审理完成，下发官方审查结果",
        },
      ],
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    closure() {
      this.visible = false;
    },
    showModel() {
      this.$refs.model.visible = true;
      this.$store.state.home.modalParms =
        this.$store.state.home[this.$route.query.type].serviceList[
          this.$route.query.index
        ];
    },
    goNews(item) {
      this.$router.push({
        path: "/news",
        query: {
          item: item,
        },
      });
    },
    async getNews() {
      const id = {
        patentlist: 2,
        trademark: 3,
        copyrightList: 4,
      };
      const paramsBz = {
        pageNum: 1,
        pageSize: 10,
        isRec: 1,
        columnId: id[this.$route.query.type],
      };
      const res = await news(paramsBz);
      if (res.code === 200) {
        this.newsList = res.data.list;
      }
    },
    toCopyrightList(index) {
      this.$router.push({
        path: "/details",
        query: {
          index: index,
          type: "copyrightList",
        },
      });
      this.getNews();
    },
    toTrademark(index) {
      this.$router.push({
        path: "/details",
        query: {
          index: index,
          type: "trademark",
        },
      });
      this.getNews();
    },
    toPatentlist(index) {
      this.$router.push({
        path: "/details",
        query: {
          index: index,
          type: "patentlist",
        },
      });
      this.getNews();
    },
    toPath(index) {
      this.$router.push({
        path: "/details",
        query: {
          index: index,
          type: this.$route.query.type,
        },
      });
      this.getNews();
    },
    firstLi(v) {
      this.selectLi = v;
    },
  },
};
</script>

<style lang="less">
@import "../../less/details.less";
.active {
  border-bottom: 2px solid #e4393c;
  margin-top: -2px;
}
</style>